




import { Component, Vue } from 'vue-property-decorator'
import CollaborationList from '@/views/collaboration/components/CollaborationList.vue'

@Component({
  name: 'incomingCollaborationList',
  components: {
    CollaborationList
  }
})

export default class extends Vue {}
